import { Component, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { AppState } from '@app/store/reducers';
import { Store } from '@ngrx/store';
import { BaseComponent } from '@shared/components/base/base.component';
import { LoaderService } from '@shared/services/loader.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { takeUntil } from 'rxjs/operators';
import { GroupActions, PermissionActions, UserActions } from './store/actions';

@Component({
  selector: 'app-root',
  template: `
    <div class="spinner-container" [style.display]="(loaderVisible | async) ? 'block' : 'none'">
      <cpa-spinner></cpa-spinner>
    </div>
    <router-outlet></router-outlet>
  `,
})
export class AppComponent extends BaseComponent implements OnInit {

  constructor(protected store: Store<AppState>, private oauthService: OAuthService, private loader: LoaderService, private router: Router) {
    super(store);
    if (!this.oauthService.hasValidAccessToken()) {
      throw new Error('No valid access token!');
    } else {
      // console.log('Root component loaded');
    }
    window.onbeforeunload = (e: any) => {
      this.loader.show();
    };
  }

  get loaderVisible() {
    return this.loader.loaderVisible$;
  }

  public warn(msg: string): void {
    window.alert(msg);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.router.events.pipe(takeUntil(this.$ngDestroyed)).subscribe((event: RouterEvent) => {
      if (event instanceof NavigationStart) {
        this.loader.show();
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
        this.loader.hide();
      }
    });
    this.store.dispatch(UserActions.GET_OR_LOAD_PROFILE());
    this.store.dispatch(PermissionActions.GET_OR_LOAD());
    this.store.dispatch(GroupActions.LOAD_GROUPS());
  }
}
