import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FaConfig, FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core';
// eslint-disable-next-line max-len
import { faAbacus, faAngleDown, faAngleRight, faArrowRight, faBars, faCircle, faCode, faCommentAlt, faDiamond, faEdit, faEye, faFileArchive, faFilePdf, faFileExclamation, faFileWord, faFilter, faList, faOctagon, faPen, faPencil, faPlus, faSave, faSearch, faSquare, faTh, faTrashAlt, faTriangle, faUser, faUsersCog, faUserShield, faEnvelopeOpen, faTimes, faSort, faSortUp, faSortDown, faSpinner, faCopy } from '@fortawesome/pro-regular-svg-icons';
// eslint-disable-next-line max-len
import { faBan, faBorderAll, faCheck, faCheckCircle, faCheckSquare, faChevronRight, faCircle as fasCircle, faLockAlt, faUnlockAlt, faSave as fasSave, faShareSquare, faTimesCircle, faUser as fasUser, faSortUp as fasSortUp, faSortDown as fasSortDown, faWrench as fasWrench, faDownload as fasDownload, faPlusCircle, faEdit as fasEdit } from '@fortawesome/pro-solid-svg-icons';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CpaSpinnerComponent } from '@shared/components/cpa-spinner/cpa-spinner.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { environment } from '@environments/environment';
// eslint-disable-next-line max-len
import { API_AUTH_TOKEN, APP_API_URL, APP_NAME, APP_LOGO, IDS_API_URL, PLATFORM_API, PLATFORM_HOME, PLATFORM_VERSION, SSO_API_URL } from '../shared/injections';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { AllEffects } from '@app/store/effects';
import { metaReducers, reducers } from './store/reducers';


@NgModule({
  declarations: [AppComponent, CpaSpinnerComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FontAwesomeModule,
    /*
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateLoaderFactory,
        deps: [HttpClient, Store]
      },
      isolate: false
    }),
    */
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot(AllEffects),
    StoreRouterConnectingModule.forRoot(),
    environment.production ? [] : StoreDevtoolsModule.instrument(),
    AuthModule,
    HttpClientModule,
    AppRoutingModule,
    MatIconModule,
    NgxPermissionsModule.forRoot(),
  ],
  providers: [
    { provide: APP_NAME, useValue: 'IDS Builder' },
    { provide: APP_LOGO, useValue: null },
    { provide: PLATFORM_API, useValue: environment.core.api },
    { provide: PLATFORM_VERSION, useValue: '4' },
    { provide: PLATFORM_HOME, useValue: `${environment.core.home}` },
    { provide: API_AUTH_TOKEN, useValue: '' },
    { provide: SSO_API_URL, useValue: environment.sso.api },
    { provide: IDS_API_URL, useValue: `${environment.apiBaseUrl}/v1` },
    { provide: APP_API_URL, useValue: `${environment.apiBaseUrl}/app/v1` },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule {
  constructor(
    iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    library: FaIconLibrary,
    faIconService: FaConfig
  ) {
    // add font awesome fonts into material icon namespace
    [faFilePdf, faFileExclamation, faFileArchive, faTrashAlt, faShareSquare, faSearch, faFileWord, faEdit, fasEdit].forEach(def => {
      const svg = icon(def).html.join('\n');
      iconRegistry.addSvgIconLiteralInNamespace(def.prefix, def.iconName, this.sanitizer.bypassSecurityTrustHtml(svg));
    });
    faIconService.defaultPrefix = 'far';
    library.addIcons(
      faAbacus,
      faAngleDown,
      faAngleRight,
      faArrowRight,
      faBan,
      faBars,
      faBorderAll,
      faCheck,
      faCheckCircle,
      faCheckSquare,
      faChevronRight,
      faCircle,
      faCode,
      faCommentAlt,
      faCopy,
      faDiamond,
      faEdit,
      faEnvelopeOpen,
      faEye,
      faFileArchive,
      faFileExclamation,
      faFilePdf,
      faFileWord,
      faFilter,
      faList,
      faLockAlt,
      faOctagon,
      faPen,
      faPencil,
      faPlus,
      faPlusCircle,
      faSave,
      fasCircle,
      fasDownload,
      faSearch,
      faShareSquare,
      faSort,
      faSortDown,
      faSortUp,
      faSpinner,
      faSquare,
      fasSave,
      fasSortDown,
      fasSortUp,
      fasUser,
      fasWrench,
      faTh,
      faTimes,
      faTimesCircle,
      faTrashAlt,
      faTriangle,
      faUnlockAlt,
      faUser,
      faUsersCog,
      faUserShield,
    );
  }
}
