import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { GroupResponse } from '@shared/models/group-response.model';
import { LoaderService } from '@shared/services/loader.service';
import { UserService } from '@shared/services/user.service';
import cookie from 'js-cookie';
import { of } from 'rxjs';
import { debounceTime, filter, flatMap, map, switchMap, tap, catchError } from 'rxjs/operators';
import { GroupActions } from '../actions';
import { AppState } from '../reducers';
import { EMPTY } from '../reducers/base.reducer';
import * as GroupsSelectors from '../selectors/group.selector';

@Injectable()
export class GroupEffects {
  constructor(
    private actions$: Actions,
    private readonly loader: LoaderService,
    private readonly userService: UserService,
    private store: Store<AppState>
  ) {}

  /**
   * We are storing the selected group's id as a cookie.
   * This is the cookie name
   */
  private readonly SELECTED_GROUP_COOKIE = 'group_id';

  /**
   * When an group is selected, we'll set the cookie
   * to that group's id
   */
  $groupsSelected = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupActions.GROUP_SELECTED),
      map(action => action.group),
      filter(group => group != null),
      tap(group => cookie.set(this.SELECTED_GROUP_COOKIE, group.id)),
      map(() => EMPTY())
    )
  );

  /**
   * Sets the group to the previously selected
   * group, as defined in the cookie. If
   * group is not found within list, removes
   * cookie
   */
  $groupsLoaded = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupActions.GROUPS_LOADED),
      map(action => action.groups),
      map(groups => {
        const orgid = cookie.get(this.SELECTED_GROUP_COOKIE);
        let group = groups[0];
        if (orgid) {
          const previousOrg = groups.find(o => o.id === orgid);
          group = previousOrg ? previousOrg : group;
        }
        return group;
      }),
      map((group: GroupResponse) => {
        if (!group) {
          cookie.remove(this.SELECTED_GROUP_COOKIE);
        }
        return GroupActions.GROUP_SELECTED({ group });
      })
    )
  );

  /**
   * Will get groups from the WS and dispatch a loaded
   * event
   */
  $getGroups = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupActions.GET_GROUPS),
      debounceTime(300),
      flatMap(() => {
        this.loader.show();
        return this.userService.getUserGroups();
      }),
      tap(() => this.loader.hide()),
      map(groups =>
        GroupActions.GROUPS_LOADED({ groups: [{ id: 'all', title: 'All', global: false, createDate: new Date(), users: [] }, ...groups] })
      ),
      catchError(e => {
        this.loader.hide();
        return of(GroupActions.FAILED());
      })
    )
  );
  /**
   * This will call a load of user groups.
   * If the groups are already loaded, it will
   * simply dispatch a loaded event with them.
   * Otherwise, it will call a get organizaitons action
   */
  $loadGroups = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupActions.LOAD_GROUPS),
      switchMap(() => this.store.pipe(select(GroupsSelectors.userGroupsLoaded))),
      switchMap(loaded =>
        loaded
          ? this.store.pipe(
              select(GroupsSelectors.userGroups),
              map(orgs => GroupActions.GROUPS_LOADED({ groups: orgs }))
            )
          : of(GroupActions.GET_GROUPS())
      )
    )
  );
  /**
   * If a change occurred to a gorup, it will trigger the load
   */
  $changedGroups = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupActions.GROUP_ADDED, GroupActions.GROUP_MODIFIED, GroupActions.GROUP_DELETED, GroupActions.GROUP_ASSOC_CHANGE),
      switchMap(action => of(GroupActions.LOAD_GROUPS()))
    )
  );
}
