import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cpa-spinner',
  templateUrl: './cpa-spinner.component.html',
  styleUrls: ['./cpa-spinner.component.scss']
})
export class CpaSpinnerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
