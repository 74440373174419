import { InjectionToken } from '@angular/core';
import { API_AUTH_TOKEN, APP_NAME, APP_LOGO, PLATFORM_API, PLATFORM_HOME, PLATFORM_VERSION } from '@cpa/cpa-ui';

export { API_AUTH_TOKEN, APP_NAME, APP_LOGO, PLATFORM_API, PLATFORM_HOME, PLATFORM_VERSION };
export const SSO_API_URL = new InjectionToken<string>('url to the sso api');
export const IDS_API_URL = new InjectionToken<string>('url to the ids api');
export const APP_API_URL = new InjectionToken<string>('url to the ids app api');

export const WINDOW = new InjectionToken<Window>('Window Token');
export const LOCATION = new InjectionToken<Location>('Location Token');
