import { OnDestroy, OnInit, Directive } from '@angular/core';
import { AppState } from '@app/store/reducers';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup, UntypedFormArray, AbstractControl } from '@angular/forms';

/**
 * There are several things each component will need. This will be contained
 * in this application
 */
@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class BaseComponent implements OnInit, OnDestroy {
  /**
   *  The ngDestroyed will be used to kill connections
   * to the store when a component is destroyed
   */
  protected $ngDestroyed = new Subject<any>();
  /**
   * This is how child components will access the store,
   * allowing them to properly use ngOnDestroy
   */
  protected baseStoreObs: Observable<AppState>;
  constructor(protected store: Store<AppState>) {}

  /**
   * Creates the baseObs, will need to be called with super in child components
   */
  ngOnInit() {
    this.baseStoreObs = this.store.pipe(takeUntil(this.$ngDestroyed));
  }

  /**
   * Convenience method for template control validational conditations
   */
  isControlInvalid(control: AbstractControl) {
    return control && control.invalid && (control.touched || control.dirty);
  }

  /**
   * Force a formgroup to validate all data.
   */
  validateAllFormFields(formGroup: UntypedFormGroup, includeArrays = false) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      } else if (includeArrays && control instanceof UntypedFormArray) {
        control.controls.forEach(c => {
          if (c instanceof UntypedFormGroup) {
            this.validateAllFormFields(c as UntypedFormGroup);
          }
        });
      }
    });
  }

  /**
   * Kills connections to the store when the application
   * destroys the component
   */
  ngOnDestroy() {
    this.$ngDestroyed.next();
    this.$ngDestroyed.complete();
  }
}
